import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import Social from "./Social";
import { contact } from "../config";
import ContentWrapper from "../styles/contentWrapper";
import Underlining from "../styles/underlining";

const StyledSection = styled(motion.section)`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  margin-bottom: 6rem;
  display: flex;
  justify-content: center;
`;

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    /* Don't stretch container over the full page width */
    max-width: 45rem;
    height: 100%;
    display: inline-block;
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
    .profile {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 3rem;
      margin-bottom: 2rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        flex-direction: row;
        align-items: center;
        margin-bottom: 3rem;
      }
      .avatar {
        width: 100%;
        max-width: 8.75rem;
        border-radius: 50%;
        margin-right: 4rem;
        margin-bottom: 2rem;
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          margin-bottom: 0;
        }
      }
      .details {
        font-size: 1.125rem;
        line-height: 2rem;
      }
    }
  }
`;

const Contact = () => {
  const { title, body, name, email } = contact;
  return (
    <StyledSection id="contact">
      <StyledContentWrapper>
        <motion.div initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.5 } }}>
          <h3>{title}</h3>
          <div>{body}</div>
          <div className="profile">
            <img
              className="avatar"
              alt="avatar"
              src={`${process.env.PUBLIC_URL}/images/avatar.jpg`}
            />
            <div className="details">
              <strong>{name}</strong>
              <br />
              <a href={`mailto:${email}`}>
                <Underlining highlight>{email}</Underlining>
              </a>
            </div>
          </div>
          <Social width="9rem" padding="0.5rem 1.25rem" withIcon />
        </motion.div>
      </StyledContentWrapper>
    </StyledSection>
  );
};

export default Contact;
