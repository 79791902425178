import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import ContentWrapper from "../styles/contentWrapper";
import { hero } from "../config";

const StyledSection = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
`;

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    min-height: 85vh;
    height: 85vh;
    display: flex;
    align-items: center;
    border-bottom: 0px;
    font-weight: 400;
    margin-bottom: 0;

    .hero-title {
      font-size: 4rem;
      font-weight: 700;
      text-align: left;

      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 3.6rem;
        text-align: left;
        line-height: 1.5;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        font-size: 2rem;
        line-height: 1.5;
      }
    }

    .hero-cta {
      display: flex;

      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        justify-content: left;
      }

      & a {
        font-size: 1.4rem;

        @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
          font-size: 1.1rem;
        }
      }
    }

    .text-color-main {
      background-image: linear-gradient(
        135deg,
        ${({ theme }) => theme.colors.heroPrimary} 0%,
        ${({ theme }) => theme.colors.heroSecondary} 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      // Responsive text ${({ theme }) => theme.colors.heroSecondary} style
      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        background-image: none;
        -webkit-text-fill-color: ${({ theme }) => theme.colors.heroSecondary};
      }
      &:hover {
        transform: translateX(2px);
      }
    }

    .cta-btn {
      display: inline-block;
      position: relative;
      padding: 0.8rem 1rem;
      font-weight: bold;
      line-height: 1;
      z-index: 1;
      transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 0px;
        height: 100%;
        left: 0;
        bottom: 0;
        z-index: -1;
        transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
      }
    }

    .cta-btn--hero {
      background-image: linear-gradient(
        135deg,
        ${({ theme }) => theme.colors.heroPrimary} 0%,
        ${({ theme }) => theme.colors.heroSecondary} 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      border-width: 2px;
      border-style: solid;
      border-image: linear-gradient(
        135deg,
        ${({ theme }) => theme.colors.heroPrimary} 0%,
        ${({ theme }) => theme.colors.heroSecondary} 100%
      );
      border-image-slice: 1;
      cursor: pointer;
      // Responsive Button hero style
      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        background-image: none;
        border: 2px solid ${({ theme }) => theme.colors.heroSecondary};
        -webkit-text-fill-color: ${({ theme }) => theme.colors.heroSecondary};
      }

      &::after {
        background-image: linear-gradient(
          135deg,
          ${({ theme }) => theme.colors.heroPrimary} 0%,
          ${({ theme }) => theme.colors.heroSecondary} 100%
        );
        // Responsive Button hero style
        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
          background-image: none;
        }
      }

      &:hover {
        -webkit-text-fill-color: white;
        text-decoration: none;
        // Responsive Button hover hero style
        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
          -webkit-text-fill-color: ${({ theme }) => theme.colors.heroSecondary};
        }

        &::after {
          width: 100%;
        }
      }
    }
  }
`;

const Hero = () => {
  const { subTextOne, name, subTextTwo, cta } = hero;
  return (
    <StyledSection id="about">
      <StyledContentWrapper>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.5 } }}
        >
          <h1 className="hero-title">
            {subTextOne || "Hello, I'm"}{" "}
            <span className="text-color-main">{name || "Name"}</span>
            <br />
            {subTextTwo || "I'm a Software Engineer."}
          </h1>
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <Link to="projects" smooth duration={1000}>
                {cta || "Click Here"}
              </Link>
            </span>
          </p>
        </motion.div>
      </StyledContentWrapper>
    </StyledSection>
  );
};

export default Hero;
