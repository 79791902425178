module.exports = {
  siteShortTitle: "ab",
  hero: {
    subTextOne: "Hello, I'm",
    name: "Ali Bhangoo",
    subTextTwo: "I build things for the web.",
    cta: "Know more",
  },
  navLinks: {
    menu: [
      {
        name: "About Me",
        url: "about",
      },
      {
        name: "Experience",
        url: "projects",
      },
      {
        name: "Contact",
        url: "contact",
      },
    ],
    button: {
      useFileName: true,
      name: "Resume",
      fileName: "resume.pdf",
      url: "", // if useFileName=false, you can set an anchor link here and use the button for navigational purposes
    },
  },
  projects: [
    {
      name: "BMO",
      category: "Professional",
      emoji: "👨‍💼",
      description:
        "My current position involves working on BMO's InvestorLine product. The platform uses Angular + Ionic for the frontend, and Java for the backend.",
      tags: ["Mobile Development", "Angular", "Java", "Ionic"],
      img: "bmo",
      github: "",
      external: "https://www.bmo.com/main/personal/investments/online-investing/investorline/self-directed/",
    },
    {
      name: "Scotiabank",
      category: "Professional",
      emoji: "🐑",
      description:
        "During my time at Scotiabank, I worked on the business banking platform. The platform supported creating scheduled payments, various business admin tasks, and sending money through various methods (eg. EFT, IMT). The platform includes a React frontend, and Java + Spring Boot backend.",
      tags: ["Web Development", "React.js", "Java", "SQL"],
      img: "scotia",
      github: "",
      external: "https://www.scotiabank.com/ca/en/personal.html",
    },
    {
      name: "Royal Bank of Cannada",
      category: "Professional",
      emoji: "👨‍💼",
      description:
        "While working at RBC I developed mortgage tools for millions of customers, worked on a comprehensive UI testing strategy, provided production support, and became a technical anchor during my year and a half on the team. The applications were built using Angular for the frontend, and Java for backend.",
      tags: ["Web Development", "Angular", "Java", "Github"],
      img: "rbc",
      github: "",
      external: "https://apps.royalbank.com/apps/home-value-estimator#!/",
    },
    {
      name: "Ransomware Detection",
      category: "Honours Project",
      emoji: "👨‍💻",
      description:
        "My senior capstone project was a partnership with the University of Ottawa to build a Ransomware Detection Mechanism (RDM) tool. This tool uses machine learning to detect ransomware viruses within a network and to collect, visualize, and analyze IOCs for emotet.",
      tags: ["Python", "Kibana", "Elasticsearch"],
      img: "ransomware",
      github: "https://github.com/TranAlan/Ransomware-Detection-Mechanism",
      external: "",
    },
  ],
  contact: {
    title: "",
    body: "Feel free to reach out to me at any time, I am always interested in new stuff!",
    name: "Ali Bhangoo",
    email: "bhangooah@gmail.com",
  },
  socialMedia: [
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/ali-bhangoo/",
    },
    {
      name: "Github",
      url: "https://github.com/alibhangoo",
    },
    {
      name: "Mail",
      url: "mailto:bhangooah@gmail.com",
    },
  ],
  // -- THEME SETTINGS -----
  colors: {
    lightTheme: {
      primary: "#000000",
      secondary: "rgb(205 243 225)",
      tertiary: "#F2F2F2",
      text: "#000000",
      subtext: "#555555",
      background: "#FFFFFF",
      card: "#FFFFFF",
      scrollBar: "rgba(0, 0, 0, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
      heroPrimary: "#02aab0",
      heroSecondary: "#00cdac",
    },
    darkTheme: {
      primary: "#FAFAFA",
      secondary: "#2A2926",
      tertiary: "#252525",
      text: "rgba(255, 255, 255, 0.87)",
      subtext: "#AAAAAA",
      background: "#121212",
      card: "#1C1C1C",
      scrollBar: "rgba(255, 255, 255, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
  },
  fonts: {
    primary: "Roboto, Arial, sans-serif",
  },
};
