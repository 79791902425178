import React from "react";
import PropTypes from "prop-types";

import IconGitHub from "./github";
import IconExternal from "./external";
import IconLinkedIn from "./linkedin";
import IconMail from "./mail";

// Utility function to grab Icons by name
const Icon = ({ name, color }) => {
  switch (name.toLowerCase()) {
    case "github":
      return <IconGitHub color={color} />;
    case "external":
      return <IconExternal color={color} />;
    case "linkedin":
      return <IconLinkedIn color={color} />;
    case "mail":
      return <IconMail color={color} />;
    default:
      return null;
  }
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default Icon;
