import { BrowserRouter as Router } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { lightTheme } from "../src/styles/theme";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Projects from "./components/Projects";
import GlobalStyle from "./styles/globalStyle";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
const StyledLayoutWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
`;

function App() {
  const theme = lightTheme;
  return (
    <StyledLayoutWrapper>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Header></Header>
          <Hero></Hero>
          <Projects></Projects>
          <Contact></Contact>
        </Router>
      </ThemeProvider>
    </StyledLayoutWrapper>
  );
}

export default App;
