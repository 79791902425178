import React from "react";
// import { Container } from "react-bootstrap";
import { Link } from "react-scroll";
import styled from "styled-components";
import { footer } from "../config";
import Icon from "../icons";
import { lightTheme } from "../styles/theme";
const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  margin-top: 6rem;
`;

const StyledFooter = styled.footer`
  .footer {
    background-color: #333;
    color: white;
    padding: 0 0;

    @include respond(phone) {
      border: 0px;
    }

    &__text {
      color: darken(white, 50%);
      font-size: 1.3rem;

      a {
        color: darken(white, 50%);
        font-size: 1.3rem;
        transition: all 0.4s;
        display: inline-block;
        background-color: darkgrey;

        &:hover,
        &:active {
          color: $primary-color;
          -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
          box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
        }
      }
    }

    & hr {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 0;
      width: 50%;
      border-top: 2px solid rgba(255, 255, 255, 0.1);
    }
  }

  .social-links {
    display: flex;
    flex-direction: row;
    justify-content: center;

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      font-size: 3rem;
      width: 5rem;
      height: 5rem;
      margin: $default-font-size $default-font-size;
      transition: all 0.2s ease-in;

      &:hover {
        transform: translateY(-2px);
      }
    }
  }

  .links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    a {
      display: inline-block;
      margin-right: 2rem;
    }
    svg {
      width: 1.3rem;
      height: 1.3rem;
      transition: all 0.3s ease-out;
    }
    svg:hover {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }

  .back-to-top i {
    color: white;
    margin: 1rem 0 $default-font-size;
    transition: all 0.2s ease-in;
    cursor: pointer;

    &:hover {
      transform: translateY(-2px);
    }
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <div className="footer navbar-static-bottom">
        <span className="back-to-top">
          <Link to="about" smooth duration={1000}>
            <Icon name="angleup"></Icon>
          </Link>
        </span>
        <div className="social-links">
          {footer &&
            footer.map(({ name, url }, key) => {
              return (
                <div className="links">
                  <a
                    href={url}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    aria-label="External Link"
                  >
                    <Icon name={name} color={lightTheme.colors.subtext} />
                  </a>
                </div>
              );
            })}
        </div>
        <hr />
        {/* <p className="footer__text">
          © {new Date().getFullYear()} - Template developed by{" "}
          <a
            href="https://github.com/cobidev"
            target="_blank"
            rel="noopener noreferrer"
          >
            Jacobo Martínez
          </a>
        </p> */}

        {/* {isEnabled && <GithubButtons />} */}
      </div>
    </StyledFooter>
  );
};

export default Footer;
